import ApplicationController from "./application_controller";
// used to enable a switcher for each of the preview frames. We don't need this because only supporting v6 now
// but decided to leave this for v7
export default class extends ApplicationController {
  static targets = ["iframe", "button", "wrapper"];

  connect() {
    this.src = this.iframeTarget.src;
    this.buttonTargets.forEach((button) => {
      button.addEventListener("click", (event) => {
        if (button.dataset.version) {
          this.switchTo(event, this.srcForVersion(button.dataset.version));
        } else if (button.dataset.src) {
          this.switchTo(event, button.dataset.src);
        } else {
          console.error("expecting either version or src in dataset for app_preview button");
        }
      });
    });
    this.check();
  }

  switchTo(event, newSrc) {
    event.preventDefault();
    event.stopPropagation();
    this.src = newSrc;
    this.iframeTarget.src = this.src;
    this.check();
  }

  srcForVersion(version) {
    if (this.currentVersion == version) {
      return this.src;
    } else {
      return this.src.replace(this.otherVersion(version), version);
    }
  }

  otherVersion(version) {
    if (version == "v6") {
      return "v5";
    } else {
      return "v6";
    }
  }

  check() {
    if (this.iframeTarget.src.includes("v5")) {
      this.currentVersion = "v5";
    } else {
      this.currentVersion = "v6";
    }
    this.buttonTargets.forEach((button) => {
      if (button.dataset.version == this.currentVersion) {
        button.classList.add("btn-primary");
        button.classList.remove("btn-default");
      } else {
        button.classList.add("btn-default");
        button.classList.remove("btn-primary");
      }
    });
  }

  displayOverride() {
    if (this.wrapperTarget.style.display == "") {
      this.wrapperTarget.style.display = "none";
    } else {
      this.wrapperTarget.style.display = "";
    }
  }
}
