import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  // This is heavily influenced (stolen) from this https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
  connect() {
    const textareas = document.getElementsByClassName("textarea-auto-expand");

    for (let i = 0; i < textareas.length; i++) {
      textareas[i].setAttribute("style", "height:" + (textareas[i].scrollHeight) + "px;overflow-y:hidden;");
      textareas[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
      this.style.height = 0;
      this.style.height = (this.scrollHeight) + "px";
    }
  }
}
