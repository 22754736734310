/* global $ */
import {mix} from "./_mix";
import {EnhancedFetch} from "./enhanced_fetch";

export const InputHolder = (controller) => class extends mix(controller).with(EnhancedFetch) {
  static values = {
    pickerDefaultTime: String // should be similar to : "23:59"
  };

  autoSubmitAfter() {
    return null;
  }

  connect() {
    if(super.connect) {
      super.connect();
    }
    if (this.hasInputTarget) {
      this.previousInputValue = this.inputTarget.value;
      this.inputListener = () => {
        this.setValueFromInput();
      };
      this.inputTarget.addEventListener("blur", this.inputListener);
      this.inputTarget.addEventListener("change", this.inputListener);
      if(this.autoSubmitAfter()){
        this.inputAutoSubmitListener = () => {
          this.inputAutoSubmit();
        };
        this.inputTarget.addEventListener("input", this.inputAutoSubmitListener);
      }
    }

    if (this.hasDatetimepickergroupTarget) {
      // TODO: manually attach the datepicker
      if (!this.hasInputTarget) {
        console.error("datetimepickergroup target implies input target");
      }
      // TODO: use Tempus-Dominus (https://getdatepicker.com/6) instead of bootstrap-datetimepicker-rails == eonasdan-bootstrap-datetimepicker v4 (https://getdatepicker.com/4)
      window.$datetimepickerEnable(this.datetimepickergroupTarget, this.inputTarget);
      let viewDate = new Date();
      if (this.pickerDefaultTimeValue) {
        const hoursAndMinutes = this.pickerDefaultTimeValue.split(":");
        viewDate.setHours(hoursAndMinutes[0]);
        viewDate.setMinutes(hoursAndMinutes[1]);
      }
      $(this.datetimepickergroupTarget).data("DateTimePicker").options({viewDate: viewDate});
      $(this.datetimepickergroupTarget).on("dp.show dp.change", () => {
        if (this.hidePopover) {
          this.hidePopover();
        }
      });
      this.inputTarget.addEventListener("focus", () => {
        $(this.datetimepickergroupTarget).data("DateTimePicker").show();
      });
    } else {
      console.warn("Sad: no datetimepickergroup target");
    }
  }

  disconnect() {
    if(super.disconnect) {
      super.disconnect();
    }
    this.detachFromGrid();
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("blur", this.inputListener);
      this.inputTarget.removeEventListener("change", this.inputListener);
      if (this.inputAutoSubmitListener) {
        this.inputTarget.removeEventListener("input", this.inputAutoSubmitListener);
      }
      if (this.inputAutoSubmitTimeout) {
        clearTimeout(this.inputAutoSubmitTimeout);
      }
    }
  }


  inputAutoSubmit() {
    if (this.autoSubmitAfter() === null) {
      return;
    }
    this.lastInputAt = Date.now();
    if (this.inputAutoSubmitTimeout) {
      clearTimeout(this.inputAutoSubmitTimeout);
    }
    this.inputAutoSubmitTimeout = setTimeout(() => {
      if (this.suspendCallbacks) {
        return;
      }
      if ((Date.now() - this.lastInputAt) >= this.autoSubmitAfter()) {
        this.setValueFromInput();
      }
    }, this.autoSubmitAfter());
  }

  htmlToElement(html) {
    let template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }

  replaceElement(text) {
    if (this.beforeReplace) {
      this.beforeReplace();
    }
    let element = this.htmlToElement(text);
    document.querySelector(`#${element.id}`).replaceWith(element);
    if (this.afterReplace) {
      this.afterReplace();
    }
  }

  setValueFromInput() {
    let value = this.inputTarget.value;
    if (this.previousInputValue == value || !this.valueIsValid(value)) {
      return;
      // because we fire on multiple values, we only change when the value changes
    }
    this.previousInputValue = value;
    this.setValue(value);
  }

  valueIsValid(value) {
    return true; // or override
  }

  setValue(date) {
    console.error("setValue() should be overridden!");
  }

  completeFetchWithReplace(text) {
    this.simpleDispatch("progressbar:complete");
    this.replaceElement(text);
    window.timeZone.updateInputs({noHighlight: true});
  }

  // date-based inputs only
  withAdminTimezoneOffset(data) {
    let offsetInput = document.querySelector("input[type=hidden][name=admin_timezone_offset]");
    if (offsetInput) {
      return Object.assign({admin_timezone_offset: offsetInput.value}, data);
    } else {
      return data;
    }
  }
};
