import ApplicationController from "../application_controller";
import {mix} from "../mixins/_mix";
import {InputHolder} from "../mixins/input_holder";
import {GridCell} from "../mixins/grid_cell";

export default class extends mix(ApplicationController).with(InputHolder, GridCell) {
  static targets = ["input", "datetimepickergroup"];
  static values = Object.assign({
    taskId: String,
    participantId: String,
    url: String
  }, InputHolder.values);

  connect() {
    this.grid.registerCell(this);
    if (super.connect) {
      super.connect();
    }
  }

  detachFromGrid() {
    let detachedGrid = super.detachFromGrid();
    if (detachedGrid) {
      // sometimes disconnects twice;
      detachedGrid.unregisterCell(this);
    }
  }

  get columnId() {
    return `task-${this.taskIdValue}`;
  }

  get rowId() {
    return `participant-${this.participantIdValue}`;
  }

  beforeReplace() {
    this.destroyPopover();
  }
  afterReplace() {
    this.showPopover();
  }

  destroyPopover() {
    // doing a hide instead of destroy because otherwise occasionally get errors
    window.jQuery(this.element).popover('hide');
  }
  hidePopover() {
    window.jQuery(this.element).popover('hide');
  }
  showPopover() {
    window.jQuery(this.element).popover('show');
  }
}
