export const GridCell = (controller) => class extends controller {
  get grid() {
    if (!this._grid) {
      if (!this.element.parentElement) {
        console.warn(`${this.identifier}: no parent element`);
      }
      this._grid = this._findGrid(this.element.parentElement);
    }
    return this._grid;
  }

  detachFromGrid() {
    let detachedGrid = this._grid;
    this._grid = null;
    return detachedGrid;
  }

  _findGrid(e) {
    if (e.grid) {
      return e.grid;
    }
    return this._findGrid(e.parentElement);
  }
};
