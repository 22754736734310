import Chart from 'chart.js/auto';

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    dataUrl: String
  };

  async connect() {
    const response = await fetch(this.dataUrlValue);
    const data = await response.json();

    const whisper_models = ["large", "medium", "small", "tiny"];
    const datasets = whisper_models.map((whisper_model) => {
      return {
        label: whisper_model,
        data: data
          .filter(row => row.whisper_model == whisper_model)
          .map((row) => {
            row.x = row.audio_duration;
            row.y = row.whisper_took;
            return row;
          })
      };
    });
    const chart = new Chart(
      this.element,
      {
        type: 'scatter',
        data: {
          // labels: data.map(row => row.year),
          datasets: datasets
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  let {x, y, whisper_model, ...values} = context.raw;
                  let label = `${context.raw.whisper_model.toUpperCase()} ${this.formatValues(values)}`;
                  return label;
                }
              }
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Audio duration"
              },
              ticks: {
                callback: (value, index, ticks) => {
                  return this.formatSeconds(value);
                }
              }
            },
            y: {
              title: {
                display: true,
                text: "Whisper took"
              },
              ticks: {
                callback: (value, index, ticks) => {
                  return this.formatSeconds(value);
                }
              }
            }
          }
        }
      }
    );
  }

  formatSeconds(value) {
    let minutes = Math.floor(value / 60);
    let seconds = Math.floor(value % 60);
    return `${minutes}m ${seconds}s`;
  }

  formatValues(values) {
    let {audio_duration, whisper_took, pace, ...other_values} = values;
    return `@ ${Math.floor(values.pace * 100)}% (pace) audio_duration=${this.formatSeconds(audio_duration)} whisper_took=${this.formatSeconds(whisper_took)} ${Object.entries(other_values).map(([k, v]) => `${k}=${v}`).join(" ")}`;
  }
}
