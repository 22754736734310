export default class HumanizableString extends String {
  capitalizeFirstLetter() {
    const transformed = this.charAt(0).toUpperCase() + this.slice(1);
    return new HumanizableString(transformed);
  }

  lowerCaseExceptFirst() {
    const transformed = this.charAt(0) + this.slice(1).toLowerCase();
    return new HumanizableString(transformed);
  }

  underscoresToSpaces() {
    const camelMatch = /_/g;
    return new HumanizableString(this.replace(camelMatch, " "));
  }

  toHumanString() {
    return this.underscoresToSpaces()
      .capitalizeFirstLetter()
      .lowerCaseExceptFirst()
      .toString();
  }
}
