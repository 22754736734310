import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["rotationAmount"];
  static values = { videoSelector: String };
  connect() {
    this.videoContainer = document.querySelector(this.videoSelectorValue);
    this.videoElement = this.videoContainer.querySelector('video');
    this.posterElement = this.videoContainer.querySelector('.vjs-poster');
  }

  rotateRight() {
    this.updateViewRotation(90);
  }
  rotateLeft() {
    this.updateViewRotation(-90);
  }
  currentRotation() {
    return parseInt(this.videoElement.style.transform.replace('rotate(', '').replace('deg)', '')) || 0;
  }
  updateViewRotation(rotationIncrement) {
    let rotation = this.currentRotation() + rotationIncrement;
    this.rotationAmountTarget.value = rotation;
    this.videoElement.style.transform = `rotate(${rotation}deg)`;
    this.posterElement.style.transform = `rotate(${rotation}deg)`;
    if (rotation % 90 == 0 && !(rotation % 180 == 0)) {
      this.videoElement.classList.add("rotated-sideways");
      this.posterElement.classList.add("rotated-sideways");
    } else {
      this.videoElement.classList.remove("rotated-sideways");
      this.posterElement.classList.remove("rotated-sideways");
    }
  }
  resetRotation() {
    this.videoElement.style.transform = '';
    this.posterElement.style.transform = '';
    this.videoElement.classList.remove("rotated-sideways");
    this.posterElement.classList.remove("rotated-sideways");
  }
}
