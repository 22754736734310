import ApplicationController from "./application_controller";
import HumanizableString from "../lib/humanizable_string";

const freetextKey = "q";
export default class extends ApplicationController {
  static targets = ["clearButton", "display", "freetext"];

  connect() {
    this.metadataFilters = {};
    this.valueSetClass = this.data.get("valueSetClass");
    this.targetSelector = this.data.get("targetSelector");
    // this.blankValue = this.data.get("blankValue");

    /* build value -> display hash per key */
    this.displayLookup = {};
    for( const elem of this.element.querySelectorAll('[data-action="filters#addFilter"][data-filter-key][data-filter-value][data-filter-display]')) {
      let lookupByKey = this.displayLookup[elem.dataset.filterKey] = this.displayLookup[elem.dataset.filterKey] || {};
      lookupByKey[elem.dataset.filterValue] = elem.dataset.filterDisplay;
    }
    /* update from query params */
    /* e.g. ?metadata-20=Paris where 20 is the id of the metadata field */
    for (const [key,value] of new URLSearchParams(location.search).entries()) {
      if (this._displayTargetFor(key)) {
        this.initMetadataFiltersFromSearchParams(key, value);
        this.showMetadataFilters(key, value);
      }
    }
    this.updateTable();
    this.updateFilterSelectors();
  }

  initMetadataFiltersFromSearchParams(key, value) {
    if(key.endsWith("[]")) {
      this.metadataFilters[key] = this.metadataFilters[key] ? this.metadataFilters[key] += value : [this.metadataFilters[key]];
    } else {
      this.metadataFilters[key] = value;
    }
  }

  showMetadataFilters(key, value) {
    this._showFilter(key, value, this.displayLookup[key][value]);
  }

  clearFilters() {
    this.metadataFilters = {};
    this._hideAllDisplays();
    this.updateTable();
    this.updateFilterSelectors();
  }

  addFilter(event) {
    let elem = event.currentTarget;
    let key = elem.dataset.filterKey;
    let value = elem.dataset.filterValue;
    this.metadataFilters[key] = value;
    this._showFilter(key, value);
    this.updateTable();
    this.updateFilterSelectors();
  }

  setAndVisit(key, valueOrValues) {
    let url = new URL(location);
    let usp = new URLSearchParams(location.search);
    if (valueOrValues.constructor === Array) {
      usp.delete(key);
      valueOrValues.forEach(value => usp.append(key, value));
    } else {
      usp.set(key, valueOrValues);
    }
    url.search = usp.toString();
    this.visit(url);
  }

  removeFilter(event) {
    let elem = event.currentTarget;
    let key = elem.dataset.filterKey;
    delete this.metadataFilters[key];
    this._hideDisplay(key);
    this.updateTable();
    this.updateFilterSelectors();
  }

  visit(url) {
    // Turbolinks.visit(url);
    window.location = url;
  }

  _showFilter(key, value, display) {
    const displayTarget = this._displayTargetFor(key);
    if (displayTarget) {
      displayTarget.innerHTML = display || new HumanizableString(value).toHumanString();
      displayTarget.classList.add(this.valueSetClass);
    }
  }

  supportsSearchKey(key) {
    let target = this._displayTargetFor(key);
    if (target) {
      return target;
    }
    if (this.hasFreetextTarget && key == freetextKey) {
      return this.freetextTarget;
    }
  }

  _displayTargetFor(key) {
    for (const displayTarget of this.displayTargets) {
      if (displayTarget.dataset.filterKey === key) {
        return displayTarget;
      }
    }
  }

  _hideDisplay(key) {
    const displayTarget = this._displayTargetFor(key);
    if (displayTarget) {
      this._hideDisplayTarget(displayTarget);
    }
  }

  _hideAllDisplays() {
    for (const displayTarget of this.displayTargets) {
      this._hideDisplayTarget(displayTarget);
    }
  }

  _hideDisplayTarget(displayTarget) {
    displayTarget.textContent = "";
    displayTarget.classList.remove(this.valueSetClass);
  }

  updateTable() {
    const target = document.querySelectorAll(this.targetSelector);
    for (const elem of target) {
      if (this.matchesMetaDataFilters(elem)) {
        elem.style.display = "";
        elem.hidden = false;
      } else {
        elem.style.display = "none";
        elem.hidden = true;
      }
    }
  }

  updateFilterSelectors() {
    // for each filter in metadataFilters
    if (Object.keys(this.metadataFilters).length > 0) {
      this.clearButtonTarget.style.display = "";
    } else {
      this.clearButtonTarget.style.display = "none";
    }
  }

  matchesMetaDataFilters(elem) {
    for (let [key, value] of Object.entries(this.metadataFilters)) {
      if (this.doesntHaveKeyValueMatch(elem, key, value)) {
        return false;
      }
    }
    return true;
  }

  doesntHaveKeyValueMatch(elem, key, value) {
    return elem.getAttribute(`data-filters-target-${key}`) !== value;
  }

}
