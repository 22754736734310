import ApplicationController from "./application_controller";
const permittedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
export default class extends ApplicationController {
  static values = {
    presignedKeyUrl: String
  };
  static targets = ["logoKey", "logoFileSize", "logoContentType", "logoStatus"];
  connect() {
  }
  async uploadFileAttachment(event) {
    let file = event.target.files[0];
    if (permittedFileTypes.includes(file.type)) {
      let data = await this.getPresignedKey(file.name);
      this.uploadFile(file, data);
    } else {
      return;
    }
  }
  async getPresignedKey(filename) {
    let response = await fetch(`${this.presignedKeyUrlValue}?filename=${filename}`);
    let data = await response.json();
    return data;
  }
  uploadFile(file, data) {
    let formData = this.createFormData(data.fields, file);
    let xhr = new XMLHttpRequest();
    xhr.open("POST", data.url, true);
    xhr.send(formData);
    this.logoKeyTarget.value = data.fields.key;
    this.logoFileSizeTarget.value = file.size;
    this.logoContentTypeTarget.value = file.type;
    this.logoStatusTarget.value = "uploaded";
  }
  createFormData(fields, file) {
    let data = new FormData();
    for(var key in fields) {
      data.append(key, fields[key]);
    }
    data.append("file", file);

    return data;
  }
}
