import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["editor", "viewer"];

  connect() {
  }

  showEditor(event) {
    event.preventDefault();
    this.viewerTarget.style.display = "none";
    this.editorTarget.style.display = "block";
    window.setTimeout(() => {
      let textarea = this.editorTarget.querySelector("textarea");
      textarea.focus();
      textarea.select();
    }, 50);
  }
  hideEditor(event) {
    event.preventDefault();
    this.editorTarget.style.display = "none";
    this.viewerTarget.style.display = "block";
  }
}
