import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.button = this.element.querySelector("button[type=submit]");
    const textarea = this.element.querySelector("textarea");
    textarea.addEventListener("input",
      (event) => this.adjustButton(event.currentTarget));
    // adjust it initially (maybe already filled by super-fast copy and paste?)
    this.adjustButton(textarea);
  }

  adjustButton(textarea) {
    this.button.disabled = textarea.textLength < 1;
  }
}
