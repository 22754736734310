import ApplicationController from "./application_controller";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";

const HIDDEN_CLASS = "hidden"; // bootstrap3 hidden class

export default class extends ApplicationController {
  static targets = [ "displayTags", "editTags", "form", "inputTags" ];
  static values = {
    url: String
  };

  saveTags(event) {
    event.preventDefault();
    event.stopPropagation();
    // need to yield to event log to allow tagify to catch up.
    setTimeout(()=> this.submit(), 100);
  }

  submit() {
    Rails.fire(this.formTarget, 'submit');
  }

  cancelEditing(event) {
    event.preventDefault();
    event.stopPropagation();
    this._switchToDisplayMode();
  }

  tagsUpdated(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    this.displayTagsTarget.innerHTML = xhr.responseText;
    this._switchToDisplayMode();
  }

  showEditor(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;

    this.displayTagsTarget.classList.add(HIDDEN_CLASS);
    this.editTagsTarget.innerHTML = xhr.responseText;
    this.editTagsTarget.classList.remove(HIDDEN_CLASS);
    fetch(this.urlValue)
      .then((response) => response.json())
      .then((data) => {
        const input = this.inputTagsTarget,
          tagify = new Tagify (input, {
            whitelist: data.tags,
            editTags:  false,
            dropdown : {
              enabled       : 0,             // show the dropdown immediately on focus
              position      : "all",         // normal, full-width design
              closeOnSelect : false,          // don't keep the dropdown open after selecting a suggestion
              highlightFirst: true
            }
            // maybe want highlightFirst: false when "Edit tags"
          });
        tagify.on("dropdown:show", e => {
          const tagifyDropdown = document.querySelector(".tagify__dropdown");
          tagifyDropdown.addEventListener("click", (event) => {
            event.stopPropagation();
          });
        });
      });
    this.inputTagsTarget.autofocus = true;
  }

  _switchToDisplayMode() {
    this.editTagsTarget.classList.add(HIDDEN_CLASS);
    this.displayTagsTarget.classList.remove(HIDDEN_CLASS);
  }
}
