/* global $ */
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input", "datetimepickergroup"];
  static values = {
    pickerDefaultTime: String // should be similar to : "23:59"
  };

  connect() {
    const $ = window.jQuery;
    if (!this.hasDatetimepickergroupTarget) {
      console.error("needs datetimepickergroup");
      return;
    }
    if (!this.hasInputTarget) {
      console.error("needs input target");
      return;
    }
    // TODO: use Tempus-Dominus (https://getdatepicker.com/6) instead of bootstrap-datetimepicker-rails == eonasdan-bootstrap-datetimepicker v4 (https://getdatepicker.com/4)
    window.$datetimepickerEnable(this.datetimepickergroupTarget, this.inputTarget);
    let viewDate = new Date();
    if (this.pickerDefaultTimeValue) {
      const hoursAndMinutes = this.pickerDefaultTimeValue.split(":");
      viewDate.setHours(hoursAndMinutes[0]);
      viewDate.setMinutes(hoursAndMinutes[1]);
    }
    window.jQuery(this.datetimepickergroupTarget).data("DateTimePicker").options({viewDate: viewDate});
    this.inputTarget.addEventListener("focus", () => {
      $(this.datetimepickergroupTarget).data("DateTimePicker").show();
    });
  }
}
