import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["filtersForm"];

  connect() {
    this.targetSelector = this.data.get("targetSelector");
    this.contentTarget = document.querySelector(this.targetSelector);
  }

  updateContent(event) {
    const [, , xhr] = event.detail;
    history.replaceState({}, "", xhr.responseURL); // TODO: https://trello.com/c/VLnp5nva/1901-fix-back-button-use-pushstate
    this.contentTarget.innerHTML = xhr.responseText;
  }

  submit() {
    Rails.fire(this.filtersFormTarget, 'submit');
  }
}
