import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "meter", "bar", "emoji", "warning", "suggestions", "submit", "counter"];

  connect() {
    if (this.hasMeterTarget) {
      this.inputTarget.addEventListener("input", () => {
        this.passwordEvaluation();
      });
      // we also want it to run when you change your name
      this.element.querySelectorAll("input[name*=name]").forEach((e) => {
        e.addEventListener("input", () => {
          this.passwordEvaluation();
        });
      });
      // do it once zxcvbn is loaded, too
      setTimeout(() => {
        this.passwordEvaluation();
      }, 500); // 0.5s just enough to load zxcvbn
    }
  }

  toggleVisibility(event) {
    let elem = event.currentTarget;
    if (this.inputTarget["type"] == "password") {
      this.inputTarget["type"] = "text";
      elem.classList.add("fa-eye-slash");
      elem.classList.remove("fa-eye");
    } else {
      this.inputTarget["type"] = "password";
      elem.classList.add("fa-eye");
      elem.classList.remove("fa-eye-slash");
    }
  }

  passwordEvaluation() {
    if (window['zxcvbn']) {
      let others = ["field", "notes"];
      this.element.querySelectorAll("input[name*=email],input[name*=name]").forEach((e) => {
        others.push(...(e.value.split(/[ @.]/)));
      });
      let result = window.zxcvbn(this.inputTarget.value, others);
      let warning = result.feedback.warning;
      let suggestions = result.feedback.suggestions.join(". ");
      let score = result.score;
      this.displayFeedback({score, warning, suggestions});
    } else {
      console.error("not loaded yet");
      let warning = "";
      let suggestions = "";
      let score = "";
      this.displayFeedback({score, warning, suggestions});
    }
    // }
  }

  displayFeedback({score, warning, suggestions}) {
    const minimumScore = 3;
    const minimumLength = 10;
    const length = this.inputTarget.value.length;
    const emojis = {
      0: "😩️",
      1: "🙁",
      2: "😕",
      3: "🙂",
      4: "😇"
    };
    if (length < minimumLength && score >= minimumScore) {
      score = minimumScore - 1;
    }
    if (length < minimumLength && !suggestions) {
      suggestions = "Keep typing";
    }
    if (length > 0) {
      this.barTarget.style.width = `${score * (100 / 4)}%`;
      this.emojiTarget.innerText = emojis[score];
    } else {
      this.barTarget.style.width = "";
      this.emojiTarget.innerText = "";
    }
    if (warning) {
      this.meterTarget.classList.add("password--strength-bar--with-warning");
    } else {
      this.meterTarget.classList.remove("password--strength-bar--with-warning");
    }
    this.element.dataset.passwordScore = score;
    this.warningTarget.innerText = warning || "Ok";
    if (length < minimumLength) {
      this.counterTarget.innerText = `+${minimumLength - length}`;
    } else {
      this.counterTarget.innerText = "";
    }
    this.suggestionsTarget.innerText = suggestions;
    this.submitTarget.disabled = score < minimumScore || length < minimumLength;
  }
}
