import ApplicationController from "./application_controller";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";

export default class extends ApplicationController {
  static values = {
    list: {type: Array, default: ["first option", "second option", "third option"]},
    // what to do when value isn't in list
    exclusion: {type: String, default: "allow"} // also "prevent" and "error"
  };

  connect() {
    let tagify = new Tagify(this.element, {
      enforceWhitelist: this.exclusionValue == "prevent",
      mode: "select",
      whitelist: this.listValue,
      dropdown: {
        highlightFirst: !this.element.value, // auto open if there
        maxItems: 100
      }
    });

    // // bind events
    tagify.on("change", e => this.applyErrorClasses(tagify));
    this.applyErrorClasses(tagify);
    // tagify.DOM.input.addEventListener("focus", e => this.selectAll(tagify)); // doesn't work
  }

  applyErrorClasses(tagify) {
    if (this.exclusionValue == "allow") {
      return;
    }
    let tagifyElement = tagify.DOM.scope;
    if (this.listValue.includes(this.getTagValue(tagify))) {
      tagifyElement.classList.remove("tagify-has-error");
    } else {
      tagifyElement.classList.add("tagify-has-error");
    }
  }

  getTagValue(tagify) {
    return tagify.value && tagify.value[0] && tagify.value[0].value;
  }

  // // NB doesn't work
  // selectAll(tagify) {
  //   window.getSelection().selectAllChildren(tagify.DOM.input);
  // }
}
