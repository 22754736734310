// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "output" ];

  connect() {
    this.outputTarget.textContent = "Connect";
  }
  greet() {
    this.outputTarget.textContent = "Hello from Stimulus";
  }
  reset() {
    this.outputTarget.textContent = "Reset";
  }
  console() {
    console.log("Hello from Stimulus (in console)");  // eslint-disable-line no-console
  }
  error() {
    this.nonExistingMethod.shouldRaiseSomething();
  }
}
