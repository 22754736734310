import ApplicationController from "../application_controller";
import {mix} from "../mixins/_mix";
import {GridCell} from "../mixins/grid_cell";

export default class extends mix(ApplicationController).with(GridCell) {
  static targets = ["input"];
  static values = {
    id: String
  };

  connect() {
    this.grid.registerCell(this);
    this._registerChecked();
    this.inputTarget.addEventListener("click", (e) => {
      this.changeCheckbox();
    });
  }

  disconnect() {
    let detachedGrid = this.detachFromGrid();
    if (detachedGrid){
      detachedGrid.unregisterCell(this);
    }
  }

  get columnId() {
    return "select";
  }

  get rowId() {
    return `${this.idValue}`;
  }

  changeCheckbox() {
    this._registerChecked();
    this.grid.redrawHead(this);
  }

  applyValue(value) {
    this.inputTarget.checked = this.hidden() ? false : value;
    this._registerChecked();
  }

  _registerChecked() {
    this.grid.registerRowChecked(this.rowId, this.inputTarget.checked && !this.hidden());
  }
}
