import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["answerOptionsField", "questionTypeField", "questionBodyField"];
  connect() {
    this.questionTypeFieldTargets.forEach((radio) => {
      this.radioName = radio.name;
      radio.addEventListener("click", ()=>{ this.updateAnswerOptions();});
    });
    this.updateAnswerOptions();
  }

  updateAnswerOptions(){
    if (this.questionType() == "free_text") {
      this.answerOptionsFieldTarget.classList.add("hidden");
      this.questionBodyFieldTarget.placeholder = "What's your favourite colour?";
    } else if (this.questionType() == "multiple_choice") {
      this.answerOptionsFieldTarget.classList.remove("hidden");
      this.questionBodyFieldTarget.placeholder = "Choose your favourite colour";
    } else if (this.questionType() == "ranking") {
      this.answerOptionsFieldTarget.classList.remove("hidden");
      this.questionBodyFieldTarget.placeholder = "Put the following colours in order, with your favourite first";
    }
  }

  questionType() {
    return this.element.elements[this.radioName].value;
  }
}
