import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["imageInput", "imagePreview"];

  show() {
    this.imagePreviewTargets.forEach((imagePreview) => {
      if(imagePreview.dataset.originalSrc == undefined){
        imagePreview.dataset.originalSrc = imagePreview.src;
      }
      let file = this.imageInputTarget.files[0];
      if (file) {
        imagePreview.src = URL.createObjectURL(file);
      } else {
        imagePreview.src = imagePreview.dataset.originalSrc;
      }
    });
  }
}
