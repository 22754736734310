import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["checkbox", "hiddenUnlessChecked", "select", "hiddenUnlessSelected"];

  connect() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.addEventListener("change", () => {
        this.checkboxToggled();
      });
      this.checkboxToggled();
    }
    if (this.hasSelectTarget) {
      this.selectTarget.addEventListener("change", () => {
        this.selectChanged();
      });
      this.selectChanged();
    }
  }

  checkboxToggled() {
    const checked = this.checkboxTarget.checked;
    this.hiddenUnlessCheckedTargets.forEach((elem) => {
      elem.style.display = checked ? null : "none";
    });
  }

  selectChanged() {
    const selectedValue = this.selectTarget.selectedOptions[0] && this.selectTarget.selectedOptions[0].value;
    this.hiddenUnlessSelectedTargets.forEach((elem) => {
      const displayed = selectedValue && selectedValue === elem.dataset.formDependenciesSelect;
      elem.style.display = displayed ? null : "none";
    });
  }
}
