import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["select", "advanced", "customised"];

  connect() {
    this.selectTarget.addEventListener("change", () => {
      this.adjustVisibility();
    });
    this.adjustVisibility();
  }

  adjustVisibility() {
    if (this.selectTarget.value === "advanced") {
      this.advancedTargets.forEach((e) => {
        e.style.display = "block";
      });
      this.customisedTargets.forEach((e) => {
        e.style.display = "block";
      });
    } else if (this.selectTarget.value === "customised") {
      this.advancedTargets.forEach((e) => {
        e.style.display = "none";
      });
      this.customisedTargets.forEach((e) => {
        e.style.display = "block";
      });
    } else if (this.selectTarget.value === "standard" || this.selectTarget.value === "none") {
      this.advancedTargets.forEach((e) => {
        e.style.display = "none";
      });
      this.customisedTargets.forEach((e) => {
        e.style.display = "none";
      });
    } else {
      console.error(`unexpected value: ${this.selectTarget.value}`);
    }
  }
}
