import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["default", "input", "resetButton"];

  connect() {
    this.inputTarget.addEventListener("input", ()=>{this.check();});
    this.check();
  }
  resetToDefault(event) {
    event.preventDefault();
    event.stopPropagation();
    this.inputTarget.value = this.defaultTarget.textContent;
    this.check();
  }
  check() {
    if(this.inputTarget.value.trim() == this.defaultTarget.textContent.trim()) {
      this.resetButtonTarget.style.display = "none";
    } else {
      this.resetButtonTarget.style.display = "";
    }
  }
}
