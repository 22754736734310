import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["optionOne", "optionTwo"];
  connect() {
  }
  selectOptionOne() {
    this.optionTwoTarget.classList.add("hidden");
    this.optionOneTarget.classList.remove("hidden");
  }
  selectOptionTwo() {
    this.optionOneTarget.classList.add("hidden");
    this.optionTwoTarget.classList.remove("hidden");
  }
}
