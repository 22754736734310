import ApplicationController from "../application_controller";
import {mix} from "../mixins/_mix";
import {GridCell} from "../mixins/grid_cell";

export default class extends mix(ApplicationController).with(GridCell) {
  static targets = ["input", "apply"];
  static values = {
    taskId: String
  };

  connect() {
    this.grid.registerColumn(this);
    this.inputState = "";
    this.inputTarget.addEventListener("click", (e) => {
      this.changeCheckbox();
    });
    this.redraw();
  }

  disconnect() {
    this.detachFromGrid();
  }

  get columnId() {
    return "select";
  }

  redraw() {
    let inputStateWas = this.inputState;
    this.inputState = this._findStateFromCells();
    if (this.inputState === "checked") {
      this.inputTarget.indeterminate = false;
      this.inputTarget.checked = true;
      this.grid.anyRowsSelected = true;
    } else if (this.inputState === "unchecked") {
      this.inputTarget.indeterminate = false;
      this.inputTarget.checked = false;
      this.grid.anyRowsSelected = false;
    } else {
      this.inputTarget.indeterminate = true;
      this.grid.anyRowsSelected = true;
    }
    this.inputTarget.readonly = true;
    if (this.inputState !== inputStateWas) {
      this.grid.redrawHeads(this);
    }
  }

  _findStateFromCells() {
    let cells = this.grid.cellsForColumnId(this.columnId) || [];
    let state = "";
    cells.forEach((cell) => {
      if (cell.hidden()){
        // no op, ignore
      } else if(cell.inputTarget.checked) {
        if (state == "" || state == "checked") {
          state = "checked";
        } else {
          state = "indeterminate";
        }
      } else {
        if (state == "" || state == "unchecked") {
          state = "unchecked";
        } else {
          state = "indeterminate";
        }
      }
    });
    return state || "checked";
  }

  changeCheckbox() {
    if (this.inputState === "checked") {
      this.applyValueToCells( false);
    } else {
      this.applyValueToCells( true);
    }
    this.redraw();
  }

  applyValueToCells(value) {
    this.grid.cellsForColumnId(this.columnId).forEach((cell) => {
      if (cell) {
        cell.applyValue(value);
      }
    });
  }
}
