import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "quote", "destroy" ];
  connect() {
  }
  remove(event) {
    event.preventDefault();
    this.destroyTarget.setAttribute("value", "true");
    this.quoteTarget.remove();
  }
}
