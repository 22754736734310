import ParticipantRowTaskColumnCell from "./participant_row_task_column_cell";

export default class extends ParticipantRowTaskColumnCell {
  static targets = ParticipantRowTaskColumnCell.targets;
  static values = ParticipantRowTaskColumnCell.values;

  setValue(date) {
    this.simpleDispatch("progressbar:add");
    this.enhancedFetch(this.urlValue, "PATCH", {assignment: {deadline: date}, partial: "assignment_grid/deadlines/td"})
      .then(data => data.text())
      .then(text => this.completeFetchWithReplace(text));
  }
}
