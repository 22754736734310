import Chart from 'chart.js/auto';

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    dataUrl: String
  };

  async connect() {
    const response = await fetch(this.dataUrlValue);
    const data = await response.json();

    const chart = new Chart(
      this.element,
      {
        type: 'bar',
        data: data,
        options: {
          scales: {
            x: {
              stacked: true
            },
            y: {
              ticks: {
                callback: function (val, index) {
                  if (parseInt(val) == val) {
                    return this.getLabelForValue(val);
                  } else {
                    return '';
                  }
                }
              }
            }
          }
        }
      });
  }
}
