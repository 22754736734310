import ColumnController from "./column_controller";
import {mix} from "../mixins/_mix";
import {InputHolder} from "../mixins/input_holder";

export default class extends mix(ColumnController).with(InputHolder) {
  static targets = ColumnController.targets.concat();
  static values = Object.assign({
    taskId: String,
  },ColumnController.values);

  applyAll(event) {
    let value = this.inputTarget.value;
    this._applyAll(cell => cell.setValue(value));
  }
  setValue(date) {
    this.simpleDispatch("progressbar:add");
    let urlValue = `/tasks/${this.taskIdValue}`;
    this.enhancedFetch(urlValue, "PATCH", {task: {deadline: date}, partial: "assignment_grid/deadlines/th"})
      .then(data => data.text())
      .then(text => this.completeFetchWithReplace(text));
  }
}
