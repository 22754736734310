import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    this.anyRowsSelected = false;
    this.attach(); // this.element.grid = this
    this.heads = [];
    this.cellsByColumnAndRow = {};
    this.rowChecked = {};
  }

  disconnect() {
    this.heads = null;
    this.cellsByColumnAndRow = null;
    this.rowChecked = null;
  }

  registerColumn(head) {
    this.heads.push(head);
  }

  registerRowChecked(rowId, checked) {
    this.rowChecked[rowId] = checked;
  }

  rowIsChecked(rowId) {
    return this.rowChecked[rowId];
  }

  registerCell(cell) {
    if (!this.cellsByColumnAndRow[cell.columnId]) {
      this.cellsByColumnAndRow[cell.columnId] = {};
    }
    this.cellsByColumnAndRow[cell.columnId][cell.rowId] = cell;
  }

  unregisterCell(cell) {
    let c = this.cellsByColumnAndRow[cell.columnId];
    if (c) {
      if (c[cell.rowId]) {
        c[cell.rowId] = null;
      }
    }
  }

  redrawHeads(exceptHead) {
    this.heads.forEach((head) => {
      if (head !== exceptHead) {
        head.redraw();
      }
    });
  }

  redrawHead(cell) {
    let head = this._findHeadByColumnId(cell.columnId);
    if (head) {
      head.redraw();
    } else {
      console.warn(`no head found for ${cell.columnId}`);
    }
  }

  // returns an array of cells AND nulls.
  cellsForColumnId(columnId) {
    let c = this.cellsByColumnAndRow && this.cellsByColumnAndRow[columnId];
    if (c) {
      return Object.values(c);
    } else {
      return [];
    }
  }

  _findHeadByColumnId(columnId) {
    return this.heads.find(head => head.columnId === columnId);
  }
}
