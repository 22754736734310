import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "fields", "destroy", "input", "container" ];
  connect() {
  }
  remove(event) {
    event.preventDefault();
    this.destroyTarget.setAttribute("value", "true");
    this.fieldsTarget.remove();
  }
  toggleSelect(event) {
    if (this.inputTarget.checked) {
      this.containerTarget.classList.remove("analysis-theme--select-container--unselected");
      this.containerTarget.classList.add("analysis-theme--select-container--selected");
      this.destroyTarget.setAttribute("value", "false");
    } else {
      this.containerTarget.classList.remove("analysis-theme--select-container--selected");
      this.containerTarget.classList.add("analysis-theme--select-container--unselected");
      this.destroyTarget.setAttribute("value", "true");
    }
  }
}
