/* global moment */
import ColumnController from "./column_controller";
import {mix} from "../mixins/_mix";
import {InputHolder} from "../mixins/input_holder";

export default class extends mix(ColumnController).with(InputHolder) {
  static targets = ColumnController.targets.concat(["applyFuture", "changeForm", "futureForm"]);
  static values = Object.assign({
    taskId: String,
    applyMode: String,
  },ColumnController.values);


  connect() {
    super.connect();
    ["input", "focus", "change"].forEach((eventName) => {
      this.inputTarget.addEventListener(eventName, () => {
        this.redrawClasses();
      });
    });
    this.redrawClasses();
    this.applyMode = this.applyModeValue;
    if (this.applyMode == "future") {
      // only happens when you've just updated the goLiveAt
      this.showForm(null, "future", false);
    }
  }

  showFutureForm(e) {
    this.applyMode = "future";
    this.showForm(e, "future");
  }

  showChangeForm(e) {
    this.applyMode = "now";
    this.showForm(e, "change");
  }

  showForm(e, form, show = true) {
    if(e) {
      e.stopPropagation();
    }
    this.changeFormTarget.style.display = form === "future" ? "none" : "block";
    this.futureFormTarget.style.display = form === "future" ? "block" : "none";
    this.redrawClasses();
    if(form === "future" && show) {
      this.showDatetimepicker();
    }
  }

  applyFutureAll() {
    let v = this.inputDateValue(this.inputTarget.value);
    if (!v || !v.future) {
      return;
    }
    let value = this.inputTarget.value;
    this._applyAll(cell=> cell.goLiveInFuture(value));
  }

  goLiveAll() {
    this._applyAll(cell => cell.goLiveNow());
  }

  unassignAll() {
    this._applyAll(cell => cell.unassign());
  }

  redrawClasses() {
    let displayMode = this.applyMode;
    if (this.applyMode === "future") {
      let v = this.inputDateValue(this.inputTarget.value);
      let disabled = !(v && v.future);
      this.applyFutureTarget.disabled = disabled;
      if (disabled) {
        displayMode = "draft";
      }
    }
    this.element.classList.remove("assignment-grid--assignment--future", "assignment-grid--assignment--draft");
    this.element.classList.add(`assignment-grid--assignment--${displayMode}`);
  }

  inputDateValue(value) {
    const dateFormat = "DD/MM/YYYY HH:mm"; // TODO: https://trello.com/c/I8dv7hvx/715-centralize-datetime-formats-and-allow-for-user-adjustment
    let m = moment(value, dateFormat);
    if (!m.isValid()) {
      return false;
    }
    // let local = m.tz(timeZone.current());
    if (m.isAfter(new Date())) { // TODO: deal with timezone translation for now...
      return {
        future: true
      };
    } else {
      return {
        future: false
      };
    }
  }

  valueIsValid(value) {
    let v = this.inputDateValue(value);
    return v && v.future;
  }

  setValue(date) {
    this.simpleDispatch("progressbar:add");
    let urlValue = `/tasks/${this.taskIdValue}`;
    this.enhancedFetch(urlValue, "PATCH", {task: {auto_publish_at: date}, partial: "assignment_grid/assign/th"})
      .then(data => data.text())
      .then(text => this.completeFetchWithReplace(text));
  }
}
