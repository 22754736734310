import ApplicationController from "./application_controller";
import {convertTimeStringToSeconds} from "../lib/utility";

const HIDDEN_CLASS = "hidden"; // bootstrap3 hidden class
const RATE_KEY = "fieldnotesEntryPlayerRate"; // localStorage key
const VOLUME_KEY = "fieldnotesEntryPlayerVolume"; // localStorage key
const SUBTITLES_STATE_KEY = "fieldnotesEntryPlayerSubtitlesState"; // localStorage key
const SUBTITLES_STATE_SHOW = "show";
const SUBTITLES_STATE_HIDE = "hide";

/* global videojs */

export default class extends ApplicationController {
  static targets = ["entryPlayer", "modal", "modalOverlay", "entryData"];
  static values = {
    closeUrl: String
  };

  connect() {
    document.querySelector('body').classList.add('modal-open');
    this.enableClickListeners();
    this.enableKeyupListeners();
    this.setupVideoJs();
  }

  _setCloseUrl() {
    history.pushState(undefined, undefined, this.closeUrlValue);
  }

  disconnect() {
    // TODO: remove these event listeners on teardown (TURBO)
    this.removeListeners();
  }

  closeModal() {
    document.querySelector('body').classList.remove("modal-open");
    this.teardownVideoJs();
    this.removeListeners();
    this._setCloseUrl();
    this.modalOverlayTarget.remove();
  }

  togglePlayback() {
    if (!this.videoJsElement) {
      return;
    } else if (this.videoJsElement.classList.contains('vjs-playing')) {
      this.videoJsInstance.pause();
    } else {
      this.videoJsInstance.play();
    }
  }

  removeListeners() {
    this.removeKeyupListeners();
    this.removeClickListeners();
  }

  removeKeyupListeners() {
    if (this.modalKeyupListener) {
      document.removeEventListener("keyup", this.modalKeyupListener);
      this.modalKeyupListener = null;
    }
  }
  removeClickListeners() {
    if (this.modalClickListener) {
      document.removeEventListener("click", this.modalClickListener);
      this.modalClickListener = null;
    }
  }
  enableKeyupListeners() {
    this.modalKeyupListener = (event) => {
      if (event.key === "Escape") {
        this.closeModal();
      } else if (event.key === " ") {
        this.togglePlayback();
      }
    };
    document.addEventListener("keyup", this.modalKeyupListener);
  }
  enableClickListeners() {
    this.modalClickListener = (event) => {
      const isClickInside = this.modalTarget.contains(event.target);
      if (!isClickInside) {
        this.closeModal();
      }
    };
    document.addEventListener("click", this.modalClickListener);
  }
  // entryPlayer specific

  get videoJsElement() {
    return this.entryPlayerTarget.querySelector("#videojs-player");
  }

  setupVideoJs() {
    if (!this.videoJsElement) {
      return;
    }
    this._turnOffSubtitlesIfHidden();
    videojs(this.videoJsElement, {
      controls: true,
      autoplay: true,
      inactivityTimeout: 0,
      playbackRates: [0.5, 1, 1.2, 1.5, 2],
      textTrackSettings: false, // Don't want them to have too many options - subtitle colour, size etc
      languages: {
        en: {
          "captions off": "Subtitles off"
        }
      },
      controlBar: {
        seekToLive: false,
        volumePanel: {
          inline: false // make volume-bar vertical
        }
      }
    }, () => {
      this.videoJsInstance = this.videoJsElement.player;
      this.videoJsInstance.playbackRate(this.playbackRate);
      if (this.playbackVolume !== undefined) {
        this.videoJsInstance.volume(this.playbackVolume);
      }
      this.videoJsInstance.on('ratechange', () => {
        this.storeRatechange();
      });
      this.videoJsInstance.on('volumechange', () => {
        this.storeVolumechange();
      });
      this.videoJsInstance.textTracks().on("change", () => {
        this._storeSubtitlesState();
      });
      if (!this.videoJsElement.dataset.tracksrc) {
        this.videoJsInstance.controlBar.subsCapsButton.disable(); // Disable subtitles button if no subtitles
      }
    });
  }
  _turnOffSubtitlesIfHidden() {
    if (this.subtitlesState === SUBTITLES_STATE_HIDE) {
      const textTrack = this.videoJsElement.querySelector('track[kind=captions]');
      if (textTrack) { // Could be an audio entry in which case there would be no text track and therefor an error without this check. Though imagine we will be adding text tracks to audio entries eventually.
        textTrack.removeAttribute('default');
      }
    }
  }

  _storeSubtitlesState() {
    if (this.videoJsInstance.textTracks_.tracks_[0].mode == "showing") {
      localStorage.setItem(SUBTITLES_STATE_KEY, SUBTITLES_STATE_SHOW);
    } else if (this.videoJsInstance.textTracks_.tracks_[0].mode == "disabled") {
      localStorage.setItem(SUBTITLES_STATE_KEY, SUBTITLES_STATE_HIDE);
    }
  }
  storeRatechange() {
    localStorage.setItem(RATE_KEY, this.videoJsInstance.playbackRate());
  }
  get subtitlesState() {
    return localStorage.getItem(SUBTITLES_STATE_KEY);
  }
  get playbackRate() {
    return localStorage.getItem(RATE_KEY) || 1;
  }

  storeVolumechange() {
    localStorage.setItem(VOLUME_KEY, this.videoJsInstance.muted() ? 0 : this.videoJsInstance.volume());
  }

  get playbackVolume() {
    return localStorage.getItem(VOLUME_KEY) || undefined;
  }

  teardownVideoJs() {
    if (this.videoJsInstance) {
      this.videoJsInstance.dispose();
      this.videoJsInstance = null;
    }
    if (this.videoJsElement) {
      this.videoJsElement.remove();
      this.videoJsElement = null;
    }
  }

  switchTab(event) {
    event.preventDefault();
    event.stopPropagation();
    const [, , xhr] = event.detail;
    this.entryDataTarget.innerHTML = xhr.responseText;
  }

  skipToTime(event) {
    let time_in_seconds = convertTimeStringToSeconds(event.currentTarget.dataset.time);
    this.videoJsInstance.currentTime(time_in_seconds);
    this.videoJsInstance.play(); // If video has reached end, it will be paused and therefore it's good to initiate play when skipping.
  }

  copyLinkToClipboard(event) {
    navigator.clipboard.writeText(window.location.href);
    event.currentTarget.innerHTML = `<span class="text-muted">Added to clipboard<i class="fas fa-check" aria-hidden="true"></i></span>`;
  }
}
