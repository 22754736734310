import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["progress", "progressBar"];

  connect() {
    this.reset();
  }

  reset() {
    this.queuedCount = 0;
    this.queueCompletedCount = 0;
  }

  addItem() {
    this.queuedCount += 1;
    this.displayProgress();
  }

  completeItem() {
    this.queueCompletedCount += 1;
    this.displayProgress();
  }

  displayProgress() {
    this.displayFractionProgress(this.queueCompletedCount / this.queuedCount);
    if (this.queuedCount == this.queueCompletedCount) {
      this.reset();
    }
  }

  displayFractionProgress(fraction) {
    this.progressBarTarget.style.width = `${fraction * 100}%`;
    this.progressTarget.style.display = "block";
    if (fraction == 1) {
      // console.log("setting timeout");
      setTimeout(() => {
        this.progressTarget.style.display = "none";
      }, 500);
    }
  }
}
