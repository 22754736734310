import Chart from 'chart.js/auto';

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    dataUrl: String
  };

  async connect() {
    const response = await fetch(this.dataUrlValue);
    const data = await response.json();

    this.locale = navigator.languages === undefined ? navigator.language : navigator.languages[0];
    this.dateTimeOptions = {weekday: "short", hour: "numeric", minute: "numeric"};
    const datasets = [{
      label: "Queue size",
      data: data
        .map((row) => {
          row.x = new Date(row._time);
          row.y = row._value;
          return row;
        })
    }];

    const chart = new Chart(
      this.element,
      {
        type: 'line',
        data: {
          labels: data.map(row => row.x),
          datasets: datasets
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  let {x, y, ...values} = context.raw;
                  let label = `${y} @ ${this.formatDateTime(x)}`;
                  return label;
                }
              }
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "When"
              },
              ticks: {
                callback: (value, index, ticks) => {
                  let datum = data[index];
                  if (datum) {
                    // should be able to determine default time format!
                    return this.formatDateTime(datum.x);
                  } else {
                    return "";
                  }
                }
              }
            },
            y: {
              min: 0,
              // suggestedMax: 1 * 60 * 60,
              title: {
                display: true,
                text: "Queue size"
              },
              ticks: {
                // callback: (value, index, ticks) => {
                //   return this.formatAsHoursMinutes(value);
                // },
                // stepSize: 10 * 60
              }
            }
          }
        }
      }
    );
  }

  formatAsHoursMinutes(value) {
    let hours = Math.floor(value / 3600);
    let minutes = Math.floor(value / 60) - (60 * hours);
    minutes = new Intl.NumberFormat("en", {
      minimumIntegerDigits: 2,
    }).format(minutes);

    if (hours > 0) {
      return `${hours} h ${minutes}`;
    } else {
      return `${minutes}`;
    }
  }

  formatDateTime(time) {
    return new Intl.DateTimeFormat(this.locale, this.dateTimeOptions).format(time);
  }
}
