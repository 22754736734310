export const EnhancedFetch = (controller) => class extends controller {
  // for post, put and patch etc
  // includes xhr and csrf token
  // optional body (which will be jsonified
  enhancedFetch(url, method, body) {
    let fetchOptions = {
      method: method,
      credentials: "same-origin", // "include" ?
      dataType: "script",
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "X-Requested-With": "XMLHttpRequest"
      }
    };
    if (body) {
      fetchOptions.body = JSON.stringify(this.withAdminTimezoneOffset(body));
      fetchOptions.headers["Content-Type"] = "application/json";
    }
    return fetch(url, fetchOptions);
  }
};
