/* global require */
import ApplicationController from "./application_controller";

const anchorJS = require("anchor-js");

export default class extends ApplicationController {
  initialize() {
    this.anchors = new anchorJS();
  }

  connect() {
    this.anchors.add("[data-controller=guidebook-content] h2, [data-controller=guidebook-content] h3, [data-controller=guidebook-content] h4, [data-controller=guidebook-content] h5");
    window.addEventListener("hashchange",
      (event) => this.hashChanged());
    this.hashChanged();
    let h1 = document.querySelector("h1");
    if (h1 && h1.textContent) {
      document.querySelector("title").textContent = `Field Notes Guidebook : ${h1.textContent}`;
    }
  }

  hashChanged() {
    if (!location.hash) {
      return;
    }
    // const verticalOffset = -100; //px
    let timeOut = 200;
    let hashElement = this.element.querySelector(location.hash);
    if (hashElement) {
      window.setTimeout(()=>{
        hashElement.scrollIntoView(true);
      }, timeOut);
    }
  }

}
