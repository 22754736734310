/* global $ */
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.loaded = false;
    $(this.element).on("show.bs.collapse", () => {
      this.load();
    });
  }

  load() {
    if (this.loaded) {
      return;
    }
    $.ajax({
      url: this.data.get("entryDetailsPath"),
      type: "GET",
      dataType: "script"
    });
    this.loaded = true;
  }
}
