import ParticipantRowTaskColumnCell from "./participant_row_task_column_cell";

export default class extends ParticipantRowTaskColumnCell {
  static targets = ParticipantRowTaskColumnCell.targets.concat(["futureForm", "changeForm"]);
  static values = ParticipantRowTaskColumnCell.values;


  assign(event) {
    if (event) {
      event.preventDefault(); // event is not always supplied
    }
    this.createOrUpdateAssignment({go_live_at: ""});
  }

  showFutureForm(event) {
    this.showForm(event, "future");
  }

  showChangeForm(event) {
    this.showForm(event, "change");
  }

  showForm(event, form) {
    if (event) {
      event.preventDefault(); // event is not always supplied
    }
    this.futureFormTarget.style.display = (form === "future" ? "block" : "none");
    this.changeFormTarget.style.display = (form === "change" ? "block" : "none");
  }

  // api of ParticipantRowTaskColumnCell
  setValue(date) {
    this.goLiveInFuture(date);
  }

  goLiveInFuture(date) {
    this.createOrUpdateAssignment({go_live_at: date});
  }

  goLiveNow(event) {
    if (event) {
      event.preventDefault(); // event is not always supplied
    }
    this.createOrUpdateAssignment({assignment_status: "assigned_now"});
  }

  unassign(event) {
    // noop if no assignment yet?
    if (event) {
      event.preventDefault(); // event is not always supplied
    }
    this.createOrUpdateAssignment({assignment_status: "no_longer_assigned"});
  }

  createOrUpdateAssignment(assignmentData) {
    this.simpleDispatch("progressbar:add");
    this.enhancedFetch(this.urlValue, "PATCH", {assignment: assignmentData, partial: "assignment_grid/assign/td"})
      .then(data => data.text())
      .then(text => this.completeFetchWithReplace(text));
  }

  replaceElement(text) {
    if (!this.inParticipantUnassignedTasks) {
      super.replaceElement(text);
    } else {
      if (this.beforeReplace) {
        this.beforeReplace();
      }
      this.enhancedFetch(this.assignedTasksElement.dataset.assignmentsUrl, "GET")
        .then(data => data.text())
        .then(text => this.replaceAssignedTasks(text));
      this.element.closest("tr").remove();
    }
  }

  replaceAssignedTasks(text) {
    let element = this.htmlToElement(text);
    this.assignedTasksElement.replaceWith(element);
  }

  get inParticipantUnassignedTasks() {
    return this.element.closest("table").dataset.assignCellRole == "participant-unassigned-tasks";
  }

  get assignedTasksElement() {
    return document.querySelector("#assigned-tasks");
  }
}
