import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    // from https://github.com/basecamp/trix/issues/604
    this.element.addEventListener("trix-initialize", function(e) {
      const file_tools = document.querySelector(".trix-button-group--file-tools");
      file_tools.remove();
    });
    document.addEventListener("trix-file-accept", function(e) {
      e.preventDefault();
    });
  }
}
