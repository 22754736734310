import ApplicationController from "./application_controller";
import Trix from "trix";
import "trix/dist/trix.css";
import {InputHolder} from "./mixins/input_holder";
const permittedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
export default class extends ApplicationController {
  static values = {
    projectId: String
  };
  connect() {
    if (!this.projectIdValue) {
      console.error("RichTextEditor requires projectId");
    }
    this.setupTrixAttachmentHandler();
  }
  setupTrixAttachmentHandler() {
    this.element.addEventListener("trix-file-accept", (event) => {this.rejectUnlessImage(event);}); // This gets fired before 'trix-attachment-add'
    this.element.addEventListener("trix-attachment-add", (event) => {this.uploadFileAttachment(event);});
  }
  rejectUnlessImage(event) {
    if (!permittedFileTypes.includes(event.file.type)) {
      event.preventDefault();
    }
  }
  async uploadFileAttachment(event) {
    let attachment = event.attachment;
    if (attachment.file) {
      let data = await this.getPresignedKey(attachment.file.name);
      this.uploadFile(attachment.file, data,
        (progress) => { attachment.setUploadProgress(progress); },
        (attributes) => { attachment.setAttributes(attributes); }
      );
    }
  }
  async getPresignedKey(filename) {
    let response = await fetch(`/projects/${this.projectIdValue}/generate_presigned_key?filename=${filename}`);
    let data = await response.json();
    return data;
  }
  uploadFile(file, data, progressCallback, successCallback) {
    let formData = this.createFormData(data.fields, file);
    let xhr = new XMLHttpRequest();
    xhr.open("POST", data.url, true);
    xhr.upload.addEventListener("progress", function(event) {
      let progress = event.loaded / event.total * 100;
      progressCallback(progress);
    });
    xhr.addEventListener("load", function (event) {
      if (xhr.status == 201) {
        let s3Url = xhr.responseXML.querySelector("Location").textContent.replaceAll("%2F", "/");
        let attributes = {
          url: s3Url,
          href: s3Url + "?content-disposition-attachment"
        };
        successCallback(attributes);
      }
    });
    xhr.send(formData);
  }
  createFormData(fields, file) {
    let data = new FormData();
    for(var key in fields) {
      data.append(key, fields[key]);
    }
    data.append("file", file);

    return data;
  }
}
