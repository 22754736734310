import ApplicationController from "./application_controller";
import Sortable from 'sortablejs';
export default class extends ApplicationController {
  static values = {
    taskId: Number,
    url: String
  };
  connect() {
    this.sortable = Sortable.create(this.element, {
      onChoose: this.onChoose.bind(this),
      onEnd: this.onEnd.bind(this),
      handle: "[data-sortable-handle]",
      draggable: ".question",
      ghostClass: "sortable-ghost",  // Class name for the drop placeholder
      chosenClass: "sortable-chosen",  // Class name for the chosen item
      dragClass: "sortable-drag"
    });
  }
  async onChoose() {
    document.querySelectorAll(".question-number-index").forEach((el) => {
      el.style.opacity = "0";
    });
  }

  async onEnd(event) {
    this.updateBackend();
    this.updateFrontEnd();
  }

  updateFrontEnd() {
    let arr=Array.from(this.element.querySelectorAll(".question"));
    arr.forEach((el, index) => {
      el.querySelector(".question-number-index").textContent = `${index + 1}. `;
    });
    document.querySelectorAll(".question-number-index").forEach((el) => {
      el.style.opacity = "1";
    });
  }
  updateBackend() {
    let ids_array = this.sortable.toArray();
    const data = new URLSearchParams();
    ids_array.forEach((id) => {
      data.append("integrated_survey_question_ids[]", id);
    });
    fetch(this.urlValue, {method: "PUT", headers: {
      "X-CSRF-Token": this.csrfToken,
      "X-Requested-With": "XMLHttpRequest"
    }, body: data
    });
  }
}
