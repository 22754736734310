import ApplicationController from "./application_controller";
// This is used to reload a partial every 5 seconds, until it receives a redirect and then reloads the page.
export default class extends ApplicationController {
  static values = {
    url: String
  };
  connect() {
    // Reload the partial after a delay
    setTimeout(() => {
      this.reloadContent();
    }, 5000);
  }

  async reloadContent() {
    const response = await fetch(this.urlValue);
    if (response.redirected) {
      window.location.href = response.url;
    } else {
      const html = await response.text();
      this.updateContent(html);
    }
  }
  updateContent(html) {
    this.element.outerHTML = html;
  }
}
