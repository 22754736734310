import ApplicationController from "../application_controller";
import {mix} from "../mixins/_mix";
import {GridCell} from "../mixins/grid_cell";

export default class extends mix(ApplicationController).with(GridCell) {
  static targets = ["input", "datetimepickergroup"];
  static values = {
    id: String
  };

  connect() {
    this.grid.registerColumn(this);
    if (this.hasDatetimepickergroupTarget) {
      // TODO: manually attach the datepicker
      window.$datetimepickerEnable(this.datetimepickergroupTarget, this.inputTarget);
      this.inputTarget.addEventListener("focus", () => {
        window.jQuery(this.datetimepickergroupTarget).data("DateTimePicker").show();
      });
    }
    this.redraw();
  }

  disconnect() {
    this.detachFromGrid();
  }

  get columnId() {
    return this.idValue;
  }

  _applyAll(callback) {
    // TODO: should assign to event queue
    this.grid.cellsForColumnId(this.columnId).forEach((cell) => {
      if (!cell || cell.hidden()) {
        return;
      }
      if (this.grid.rowIsChecked(cell.rowId)){
        callback(cell);
      }
    });
  }

  showDatetimepicker() {
    window.jQuery(this.datetimepickergroupTarget).data("DateTimePicker").show();
  }

  redraw() {
    if (this.grid.anyRowsSelected) {
      this.element.classList.add("assignment-grid--column-controls-visible");
    } else {
      this.element.classList.remove("assignment-grid--column-controls-visible");
    }
  }
}
