import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["submitButton"];

  connect() {
    this.fileInput = this.element.querySelector("input[type=file]");
    this.updateSubmitButton();
    this.fileInput.addEventListener("change", () => {
      this.updateSubmitButton();
    });
  }

  updateSubmitButton() {
    if (this.fileInput.value) {
      this.submitButtonTarget.style.display = "";
    } else {
      this.submitButtonTarget.style.display = "none";
    }
  }
}
