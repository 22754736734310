/*
  time_string could be in format 00:04.000 or 00:01:14.815
  so basically, it may or may not have hours, but should always have minutes and seconds.
   */
export function convertTimeStringToSeconds(time_string) {
  let without_milliseconds = time_string.split(".")[0]; // Ignore milliseconds as videojs takes seconds as input.
  let remaining_time_units_asc = without_milliseconds.split(":").reverse(); // We know that seconds will be fist after reversing. Has to be this way round as dont know if we have hours or not.
  let total_seconds = parseInt(remaining_time_units_asc[0]);
  if(parseInt(remaining_time_units_asc[1])) total_seconds += (parseInt(remaining_time_units_asc[1]) * 60); // Add minutes in seconds - should awalys have seconds, but check just in case
  if(parseInt(remaining_time_units_asc[2])) total_seconds += (parseInt(remaining_time_units_asc[2])  * 3600); // Add hours in seconds - only add hours if provided, otherwise errors.
  return total_seconds;
}
